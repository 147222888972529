
@use '../../../utils/sass/_colors.sass' as *

.titleAndDescription
  margin-top: 1.5em
  margin-bottom: 1.5em

.secondaryTitle
  font-family: 'CircularStd-Bold'
  margin-bottom: .5em
  font-size: 1.5em

.sectionDescription
  font-size: 1em
  font-family: 'HelveticaNeue-medium'

.inputsTaxpayer
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

.addressWrapper
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(5, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

.blueBtn
  color: $inputBlue
  background-color: transparent
  border-radius: 20px
  height: 40px
  width: max-content
  display: flex
  max-width: 250px
  border: 1px solid $inputBlue
  transition-duration: 300ms
  transition-property: background-color
  transition-timing-function: ease

  &:hover
    border: none
    background-color: #006FB914

.blueBtnIcon
  margin: auto
  margin-left: 1em

.blueBtnText
  margin: auto 1em

.newMember
  display: flex
  gap: 1.5em
  margin: 1em auto

.deleteMember
  color: $blueInputs
  cursor: pointer
  background: none
  border: none
  width: max-content
  height: max-content
  align-self: center



.select
  max-width: 25%
  min-width: 25%

.emptySpace
  width: 120px